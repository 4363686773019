import axios from '@/axios'
//const route = 'budget/cost'
export default{
    getAll(ItemsPerPage,page,search){
        let route = 'helper/get_all_cost'
        return  axios.get(route+'?page='+page+'&items_per_page='+ItemsPerPage+ '&search=' + search)
    },

    // getCompositionsAbc(cost_id) {
    //     const route = 'report/composition_per_cost/'
    //     return axios.get(route + cost_id)
    // },

    getCompositions(stage_id) {
        const route = 'report/composition/compositions/'
        return axios.get(route + stage_id)
    },
    getStages(budget_id) {
        const route = 'report/composition/stages/'
        return axios.get(route + budget_id)
    },

    getAbcCompositions(cost_id,filter){
        const route = 'report/composition/abc/'
        return axios.post(route + cost_id,filter)
    },
    getCompositionStages(cost_id) {
        const route = 'report/composition/abc/stages/'
        return axios.post(route + cost_id)
    },
    ExportAbcCompositions(cost_id){
        const route = 'report/composition/abc_export/'
        return axios.get(route + cost_id,{responseType: 'blob'})
    },

    exportExcel(stage_id){
        const route = "report/composition/compositions/export_excel/"
        return axios.get(route + stage_id, {responseType: 'blob'} )
    },

    exportStageExcel(cost_id) {
        const route = `report/composition/stages_export_excel/${cost_id}`
        return axios.get(route ,{responseType: 'blob'})
    },


}
