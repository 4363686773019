<template>
    <v-container>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>ABC Composições</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary"  small dark fab v-bind="attrs" v-on="on">
                            <v-icon>mdi-hammer-wrench </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in toolItems" :key="index" @click="actionMenuTool(item.action)">
                            <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-autocomplete :loading="loadingStages" v-model="filter.stage_id" @change="getCompositions()" :items="stages" item-value='id' item-text='name'  outlined rounded dense class="mt-6 ml-2" label="Etapas" ></v-autocomplete>

                <!-- <v-btn color="primary" large class="ml-2"  rounded ><v-icon>mdi-filter</v-icon>filtros</v-btn> -->

                <v-spacer></v-spacer>

            </v-toolbar>
            <v-progress-linear v-if="loadingTable"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-simple-table dense >
                <thead>
                    <tr>
                        <th class="text-left">
                            COMPOSIÇÃO
                        </th>
                        <th class="text-center">
                            UNIDADE
                        </th>
                        <th class="text-right">
                            QUANTIDADE
                        </th>
                        <th class="text-right">
                            VALOR UNIT. (R$)
                        </th>
                        <th class="text-right">
                            TOTAL
                        </th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="composition in compositions" :key="composition.id">
                        <td>{{ composition.composition_group.code + '-' + composition.code + " - " + composition.name }}</td>
                        <td class="text-center">{{ composition.unity }}</td>
                        <td class="text-right">{{ formatMoney(composition.pivot.quantity) }}</td>
                        <td class="text-right"> {{ formatMoney(composition.total_with_tax) }} </td>
                        <td class="text-right"> {{ formatMoney(composition.total_with_tax * composition.pivot.quantity ) }} </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2 ;color: white">Total:</td>
                        <td class="text-right" style="background:#1976d2; color: white;"> {{ formatMoney(calcTotal()) }}</td>
                    </tr>
                </tfoot>
            </v-simple-table>


        </v-card>

    </v-container>
</template>

<script>
import service from "../service";
// import { mapActions } from 'vuex';
export default {
    data: () => ({
        loadingTable:false,
        loadingStages:false,
        filter:{},
        costId: null,
        compositions: [],
        budgetId: null,
        toolItems: [
            { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },
        ],
        stages:[],
    }),
    computed: {

    },
    watch: {

    },
    created() {
        this.costId = this.$route.params.cost_id;
        this.getCompositions();
        //carrega as etapas
        this.loadingStages = true
        this.stages = service.getCompositionStages(this.costId).then((response)=>{
            this.stages = response.data
        }).finally(()=>{
            this.loadingStages = false
        })

        ;
    },
    methods: {
        // ...mapActions('Composition',['getCompositionStages']),
        getCompositions() {
            this.loadingTable = true
            service
                .getAbcCompositions(this.costId,this.filter)
                .then((result) => {
                    this.compositions = result.data;
                })
                .catch()
                .finally(()=>{
                    this.loadingTable = false
                });
        },
        actionMenuTool(action) {
            switch (action) {
                case "export_excel":
                    service.ExportAbcCompositions(this.costId)
                        .then((response) => {

                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'relatorio_abc_composicao.xlsx');
                            document.body.appendChild(fileLink);
                            fileLink.click();

                        }).catch((response) => {
                            console.log(response)
                        })
                    break;
            }
        },

        calcTotal() {
            let total = 0
               total = this.compositions.reduce((acumulator, current) => {
                    return acumulator + current.total_with_tax * current.pivot.quantity;
                }, 0);
            
            return total;
        },

        formatMoney(number) {
            number = this.parseNumber(number);
            return number.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>

